<template>
    <div class="page-background page-setting">

        <home-top-bar></home-top-bar>
		
		<!--div class="cpt-breadcrumb">Home / Setting</div-->

        <div class="breadcrumb_container">
            <div class="breadcrumb">
                <button id="go-home-button" type="button" @click="goHome">
                    <span id="go-home-button-text">
                        Home
                    </span>
                </button>
                <span> / Setting</span>
            </div>
        </div>
        
        <div id="container">
            <!-- <div id="container-inside-box"> -->
                <div id="username-key">Username：</div>
                <div id="username-value">{{userDetailVo.username}}</div>

                <hr />

                <div id="email-key">Email：</div>
                <div id="email-value">{{userDetailVo.email}}</div>

                <hr />

                <div id="password-set-box">
                    <div id="password">Password</div>
                    <div id="set" @click="goEnterEmailPage">Set</div>

                    <!--button id="edit-button" type="button">
                        <span id="edit-text">
                            Edit
                        </span>
                    </button-->
                </div>

                <hr />

                <div class="verify_login_switch_decription">Enable email login verification</div>
                <el-switch class="verify_login_switch" v-model="userDetailVo.verifyLogin" @change="updateVerifyLoginSwitch()" active-color="rgb(19, 206, 102)" inactive-color="rgb(136, 153, 170)"> </el-switch>
                <hr />


            <!-- </div> -->
        </div>
    </div>
</template>

<script>
import HomeTopBar from '../components/HomeTopBar.vue';
import {getUserDetail, updateVerifyLogin} from '../api';

export default {
    components: {
        HomeTopBar,
    },
    name: 'Setting',
    props: {
    msg: String
    },
    data() {
        return {
            userDetailVo: {},
            verifyLoginInitialValue: null,
        }
    },
    methods: {
        goHome(){
            this.$router.push({'name': 'home'}).catch(err => console.log(err))
        },

        goEnterEmailPage(){
            this.$router.push({'name': 'requestResetPassword', params: {defaultEmail: this.userDetailVo.email}}).catch(err => console.log(err))
        },

        updateVerifyLoginSwitch(){
            if (this.verifyLoginInitialValue !== null) {
                updateVerifyLogin(this.userDetailVo)
                .then(() => {
                    this.verifyLoginInitialValue = this.userDetailVo.verifyLogin
                })
                .catch(err => {
                    console.log(err)
                    this.userDetailVo.verifyLogin = this.verifyLoginInitialValue
                    this.$message.error({center: true, message: 'Network error'})
                })
            } else (
                this.userDetailVo.verifyLogin = false,
                this.$message.error({center: true, message: 'Network error'})
            )
        }
    },
    mounted: function() {
        getUserDetail().then(userDetailVo => {
            // console.log(userDetail)
            this.userDetailVo = userDetailVo
            this.verifyLoginInitialValue = userDetailVo.verifyLogin
        })
    },
    // updated: function() {
    //     console.log(this.userDetailVo.verifyLogin)
    // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
    .page-background {
        box-sizing: content-box;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #212A3A
    }

    .breadcrumb_container {
        position: relative;
        /* left: 50%; */
        /* top: 50%; */
        margin-left: 121px;
        /* margin-top: -295px; */
        margin-top: 88px;
        margin-right: 121px;

        /* width: 1200px; */
        height: 22px;
        /* background: #182130; */
        border-radius: 2px;
    }
    .breadcrumb {font-size: 14px; font-weight: 400; color: #FFFFFF; line-height: 22px; margin-top:12px; margin-bottom:23px;}

    #go-home-button {
        background: #212A3A;
        padding: 0px 0px;
        border-width: 0px;
        cursor:pointer;
    }

    #go-home-button-text {
        font-size: 14px;
        color: #FFFFFF;
    }

    /* **********************************************************  container  *************************************************************************** */
	

    #container {
        display: flex;
        flex-direction: column;
        position: relative;
        /* left: 50%; */
        /* top: 50%; */
        margin-left: 121px;
        /* margin-top: -295px; */
        margin-top: 24px;
        margin-right: 121px;

        /* width: 1200px; */
        height: 590px;
        background: #182130;
        border-radius: 2px;
    }

    /* #container-inside-box {
        z-index: 0;
        position: relative;
    } */

    #username-key {
        z-index: 1;
        margin-left: 66px;
        padding-top: 34px;
        width: 80px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #989DA3;
        line-height: 18px;
    }

    #username-value {
        z-index: 1;
        margin-left: 66px;
        margin-top: 11px;
        width: 36px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
    }


    hr {

        margin-top: 23px;
        width: 100%;
        height: 1px;
        background: #FFFFFF;
        opacity: 0.04;

        border: 0px;
    }


    #email-key {
        z-index: 1;
        margin-left: 66px;
        margin-top: 48px;
        width: 50px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #989DA3;
        line-height: 18px;
    }

    #email-value {
        z-index: 1;
        margin-left: 66px;
        margin-top: 11px;
        width: 143px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
    }


    #password-set-box {
        z-index: 1;

        margin-left: 66px;
        margin-top: 48px;

        /* width: 1003px; */
        height: 47px;
    }
    #password {
        z-index: 2;
        width: 64px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #989DA3;
        line-height: 18px;
    }
    #set {
        z-index: 2;
        margin-top: 11px;
        /* width: 22px; */
        width: 60px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
        cursor:pointer;
    }

    .verify_login_switch_decription {
        z-index: 1;
        margin-left: 66px;
        margin-top: 48px;
        width: 500px;
        height: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #989DA3;
        line-height: 18px;
    }
    .verify_login_switch {
        z-index: 1;
        margin-left: 66px;
        margin-top: 11px;
        width: 100px;
        height: 18px;
    }

    #edit-button {
        z-index: 2;
        position: absolute;
        margin-top: -38px;
        margin-left: 1001px;

        width: 67px;
        height: 29px;
        background: #FCB900;
        border-radius: 2px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-width: 0px;
    }

    #edit-text {
        width: 29px;
        height: 15px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 13px;
    }





























    #login-box-img-box {
        z-index: 0;
        position: absolute;
        width: 542px;
        height: 460px;
    }
    #login-box-img-box-img {
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -219px;
        margin-top: -219px;
        max-width: 438px;
        max-height: 438px;
    }



    #login-box-login {
        z-index: 0;
        position: absolute;
        right: 0%;
        bottom: 0%;
        width: 378px;
        height: 460px;
        background: #182130;
        border-radius: 2px;
    }

    

    #logo-app_name-box {
        z-index: 1;
        position: absolute;
        margin-left: 34px;
        margin-top: 40px;
        margin-right: 34px;
        margin-bottom: 382px;
        width: 310px;
        height: 38px;
    }

    #login-box-login-logo {
        z-index: 2;
        position: absolute;
        left: 61px;
        top: 0px;
        width: 38px;
        height: 38px;
    }

    #login-box-login-app-name {
        z-index: 2;
        position: absolute;
        margin-left: 105px;
        margin-top: 5px;
        margin-right: 19px;
        margin-bottom: 5px;
        width: 142px;
        height: 26px;
        font-size: 19px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 26px;
    }


    #login_text-to-signup_hyperlink-box {
        z-index: 1;
        position: absolute;
        margin-left: 34px;
        margin-top: 108px;
        margin-right: 78px;
        margin-bottom: 22px;
        width: 310px;
        height: 330px;
    }


    #login-text {
        z-index: 2;
        width: 266px;
        height: 28px;
        font-size: 19px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
    }

    #login-form-username_or_email-text {
        z-index: 2;
        margin-top: 29px;
        width: 144px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
    }

    #login-form-username_or_email-input {
        box-sizing: content-box;
        z-index: 2;
        margin-top: 12px;
        /* width: 310px;
        height: 40px; */
        width: 309px;
        height: 39px;
        border-radius: 4px;
        border: 1px solid #333B47;
    }

    #login-form-password-text {
        z-index: 2;
        margin-top: 13px;
        width: 104px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
    }
    input {
        background: #182130;  /*设置输入框背景色*/
        padding: 0px 0px;
    }

    #login-form-password-input {
        box-sizing: content-box;
        z-index: 2;
        margin-top: 12px;
        width: 309px;
        height: 39px;
        border-radius: 4px;
        border: 1px solid #333B47;
    }

    a {
        text-decoration: none;
    }

    #forget-password-box {
        z-index: 2;
        margin-top: 14px;
        width: 310px;
        height: 16px;

        text-align: right;
    }
    #forget-password {
        z-index: 3;
        width: 141px;
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A5A5A5;
        line-height: 16px;
    }

    #sign-up-box {
        z-index: 2;

        text-align: center;
    }
    #sign-up {
        z-index: 3;
        width: 51px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A5A5A5;
        line-height: 16px;
    }

    #confirm-button {
        z-index: 2;
        margin-top: 19px;
        width: 310px;
        height: 44px;
        background: #FCB900;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        border-width: 0px;
    }

    #confirm-text {
        width: 108px;
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 14px;
    }

    body {
    display: block;
    margin: 0px;
    }
</style>
